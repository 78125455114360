export enum BlogCategory {
  Company = 'company',
  Engineering = 'engineering',
  Product = 'product',
  Tutorials = 'tutorials',
  Vitess = 'vitess'
}

export type ClusterSku = {
  cpu: string
  display_name: string
  enabled: boolean
  metal: boolean
  name: string
  provider: RegionProvider | null
  ram: string
  rate: number | null
  replica_rate: number | null
  sort_order: number
  storage: string
}

export type Page = {
  page: string | null
  root: string
  url: string
}

export type Pagination = {
  hasNext: boolean
  hasPrev: boolean
  page: number
  totalPages: number
  url: string
}

export type Region = {
  current_default: boolean
  display_name: string
  location: string
  provider: RegionProvider
  slug: string
}

export enum RegionProvider {
  AWS = 'AWS',
  GCP = 'GCP'
}

export type Theme = 'ascii' | 'legacy'
